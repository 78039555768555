html {
  height: 100%;
}

body {
  height: 100%;
}

div#app {
	background-image: url(../img/frontend/Login_background.jpg);
	height: 100%;
}
