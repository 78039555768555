// Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:400,600);

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "~font-awesome/scss/font-awesome";

// Helpers
@import "../helpers";

// Global
@import "global";

// Pages
@import "pages/dashboard";

// Plugins
@import "../plugin/toastr/toastr.scss";
@import "../plugin/sweetalert/sweetalert.scss";

// Customize
@import "italogic";
